import Vue from 'vue'
import VueRouter from 'vue-router'
import env from '@/env'
import {routes} from './routes'
import store from '../store'
Vue.use(VueRouter)

export const createRouter = () => {
    const router = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        linkActiveClass: 'active',
        linkExactActiveClass: 'active',
        routes,
    })

    router.beforeEach((to, from, next) => {
        if (to.matched.some(record => record.meta.auth)) {
            if (!store.getters['auth/isLoggedIn']) {
                return next({ name: 'login' });
            } else {
                const currentUser = store.state.auth.user;
                if (!currentUser.plan_id && env.checkout) {
                    const token = localStorage.getItem('token');
                    return next({ name: 'checkout', query: { token: token } });
                }
                document.title = to.meta.title + ' | ' + env.title;
                store.commit('api/resetValues', {});
                return next();
            }
        }
        document.title = to.meta.title + ' | ' + env.title;
        next();
    });

    return router
}

const router = createRouter()
export default router
